.projects-logo-container {
	display: flex;
	align-items: center; /* Vertically center the title and image */
	padding-top: 25px;
  }
  
  .projects-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
	z-index: 999;
  }
  
  .projects-title-and-image {
	display: flex;
	align-items: center; /* Vertically center the title and image */
	margin-left: 20px; /* Adjust as needed for spacing */
  }
  
  .projects-title {
	font-size: 24px; /* Adjust font size as needed */
	margin-right: 10px; /* Adjust as needed for spacing */
  }
  
  .projects-image-container {
	flex: 0 0 auto; /* Prevent the image from growing */
	margin-left: 0px; /* Adjust as needed for spacing */
  }
  
  .projects-image-container img {
	max-width: 100%; /* Ensure the image fits within its container */
	height: auto; /* Maintain aspect ratio */
  }
  
  
  @media (max-width: 600px) {
	.projects-container {
	  padding-top: 60px;
	}
  
	.projects-project {
	  width: calc(100% / 2);
	}
  }
  